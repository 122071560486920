<template>
  <b10-base>
    <ValidationProvider
      v-slot="{ errors }"
      name="idtdireccion"
      :rules="formRules.idtdireccion"
    >
      <b10-autocomplete
        v-model="form.idtdireccion"
        :items="tdirecciones"
        item-value="idtdireccion"
        item-text="descripcion"
        label="Tipo de dirección"
        clearable
        :error-messages="errors"
      />
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors }"
      name="idzona"
      :rules="formRules.idzona"
    >
      <b10-autocomplete
        v-model="form.idzona"
        :items="zonas"
        item-value="idzona"
        item-text="descripcion"
        label="Zona"
        clearable
        :error-messages="errors"
      />
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors }"
      name="idtvia"
      :rules="formRules.idtvia"
    >
      <b10-autocomplete
        v-model="form.idtvia"
        :items="tvias"
        item-value="idtvia"
        item-text="descripcion"
        label="Tipo de vía"
        clearable
        :error-messages="errors"
      />
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors }"
      name="calle"
      :rules="formRules.calle"
    >
      <v-text-field
        v-model="form.calle"
        clearable
        flat
        label="Calle"
        :error-messages="errors"
      />
    </ValidationProvider>
    <v-text-field
      v-model="form.num"
      clearable
      flat
      label="Número"
    />
    <v-text-field
      v-model="form.otros_datos"
      clearable
      flat
      label="Otros datos"
    />
    <ValidationProvider
      v-slot="{ errors }"
      name="idpais"
      :rules="formRules.idpais"
    >
      <b10-autocomplete
        v-model="form.idpais"
        :items="paises"
        item-value="idpais"
        item-text="descripcion"
        label="País"
        clearable
        :error-messages="errors"
        @change="changePaisSelected"
      />
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors }"
      name="idprovincia"
      :rules="formRules.idprovincia"
    >
      <b10-autocomplete
        v-model="form.idprovincia"
        :items="provincias"
        item-value="idprovincia"
        item-text="descripcion"
        label="Provincia"
        clearable
        :error-messages="errors"
        @change="changeProvinciaSelected"
      />
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors }"
      name="idlocalidad"
      :rules="formRules.idlocalidad"
    >
      <b10-autocomplete
        v-model="form.idlocalidad"
        :disabled="!form.idprovincia"
        :items="localidades"
        item-value="idlocalidad"
        item-text="descripcion"
        label="Localidad"
        clearable
        :error-messages="errors"
      />
    </ValidationProvider>
    <v-text-field
      v-model="form.cp"
      clearable
      flat
      label="Código postal"
    />
    <b10-textarea
      v-model="form.observacion"
      label="Observaciones"
    />
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './ClienteDireccionFormData'
import _ from '@/utils/lodash'
import { PARAMETRO } from '@/utils/consts'

export default {
  mixins: [formMixin],
  props: {},
  data () {
    return {
      form: {
        idtdireccion: null,
        idzona: null,
        idtvia: null,
        calle: null,
        num: null,
        otros_datos: null,
        idpais: null,
        idprovincia: null,
        idlocalidad: null,
        cp: null,
        observacion: null,
      },
      formRules: {
        idtdireccion: { required: true },
        idzona: { required: true },
        idtvia: { required: true },
        calle: { required: true },
        idpais: { required: true },
        idprovincia: { required: true },
        idlocalidad: { required: true },
      },
      paises: [],
      tdirecciones: [],
      zonas: [],
      tvias: [],
      localidades: [],
      provincias: [],
    }
  },
  async created () {
    const parametrosNecesarios = [
      PARAMETRO.parametros.PAIS_POR_DEFECTO,
      PARAMETRO.parametros.TIPO_DIRECCION_POR_DEFECTO,
      PARAMETRO.parametros.ZONA_POR_DEFECTO,
      PARAMETRO.parametros.TIPO_VIA_POR_DEFECTO,
    ]
    const resp = await Data.selectLookups(this, parametrosNecesarios)
    this.paises = resp.data.selectPais.result.dataset
    this.tdirecciones = resp.data.selectTdireccion.result.dataset
    this.zonas = resp.data.selectZona.result.dataset
    this.tvias = resp.data.selectTvia.result.dataset
    // valores por defecto
    const parametroPaisDefecto = _.find(resp.data.selectParametro.result.dataset, { idparametro: PARAMETRO.parametros.PAIS_POR_DEFECTO })
    if (parametroPaisDefecto) {
      const existePaisDefecto = _.find(this.paises, { idpais: parseInt(parametroPaisDefecto.valor) })
      if (existePaisDefecto) {
        this.$set(this.form, 'idpais', parseInt(parametroPaisDefecto.valor))
        this.changePaisSelected(this.form.idpais)
      }
    }
    const parametroTdireccionDefecto = _.find(resp.data.selectParametro.result.dataset, { idparametro: PARAMETRO.parametros.TIPO_DIRECCION_POR_DEFECTO })
    if (parametroTdireccionDefecto) {
      const existetDireccionDefecto = _.find(this.tdirecciones, { idtdireccion: parseInt(parametroTdireccionDefecto.valor) })
      if (existetDireccionDefecto) {
        this.$set(this.form, 'idtdireccion', parseInt(parametroTdireccionDefecto.valor))
      }
    }
    const parametroZonaDefecto = _.find(resp.data.selectParametro.result.dataset, { idparametro: PARAMETRO.parametros.ZONA_POR_DEFECTO })
    if (parametroZonaDefecto) {
      const existeZonaDefecto = _.find(this.zonas, { idzona: parseInt(parametroZonaDefecto.valor) })
      if (existeZonaDefecto) {
        this.$set(this.form, 'idzona', parseInt(parametroZonaDefecto.valor))
      }
    }
    const parametroTviaDefecto = _.find(resp.data.selectParametro.result.dataset, { idparametro: PARAMETRO.parametros.TIPO_VIA_POR_DEFECTO })

    if (parametroTviaDefecto) {
      const existeTviaDefecto = _.find(this.tvias, { idtvia: parseInt(parametroTviaDefecto.valor) })
      if (existeTviaDefecto) {
        this.$set(this.form, 'idtvia', parseInt(parametroTviaDefecto.valor))
      }
    }
    await this.loadForm()
  },
  methods: {
    async loadFormData (id) {
      const formData = await Data.selectFormData(this, id)
      this.form = formData
      // como los campos de zona son 3 pero uso uno solo, en el edit le asigno uno
      this.$set(this.form, 'idzona', formData.idzona_comercial)
      // fuerzo el change para que cargue los selects
      this.changePaisSelected(formData.idpais)
      this.changeProvinciaSelected(formData.idprovincia)
    },
    async changeProvinciaSelected (value) {
      if (value) {
        const [datasetLocalidades] = await Data.selectLocalidad(this, value)
        this.localidades = datasetLocalidades
      } else {
        this.$set(this.form, 'idlocalidad', null)
        this.localidades = []
      }
    },
    async changePaisSelected (value) {
      if (value) {
        const [datasetProvincias] = await Data.selectProvincia(this, value)
        this.provincias = datasetProvincias
      } else {
        this.$set(this.form, 'idprovincia', null)
        this.$set(this.form, 'idlocalidad', null)
        this.provincias = []
        this.localidades = []
      }
    },
  },
}
</script>
